import styled from '@emotion/styled';
import {
  Button,
  COLORS,
  Option,
  RADIUSES,
  SHADOWS,
  Select as SortlistSelect,
  TYPOGRAPHY,
} from '@sortlist-frontend/design-system';
import { ReactNode } from 'react';
import { components, ControlProps, GroupBase, OptionProps, StylesConfig } from 'react-select';

// TODO: This components are good candidates to move to the design-system
export const ButtonNoBorderRadius = styled(Button)`
  border-radius: 0 !important;
`;

export const Select = styled(SortlistSelect)`
  div > div > div > div > div:nth-of-type(1) {
    flex: 1;
  }

  .react-select__value-container {
    ${({ minHeight }: { minHeight?: string }) => (minHeight ? `min-height: ${minHeight};` : '')}
  }
`;

export const getSelectStyles = (error: boolean): StylesConfig<unknown, boolean> => {
  return {
    control: (provided, state) => ({
      ...provided,
      border: error ? `1px solid ${COLORS.DANGER_500}` : `1px solid ${COLORS.SECONDARY_700}`,
      boxShadow: SHADOWS.DEPTH_2.SECONDARY_900,
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      caretColor: 'transparent',
      borderTopRightRadius: '0.375rem',
      borderBottomRightRadius: '0.375rem',
      borderTopLeftRadius: '0.375rem',
      borderBottomLeftRadius: '0.375rem',
      backgroundColor: COLORS.NEUTRAL_100,
      '&:hover': {
        border: error ? `1px solid ${COLORS.DANGER_500}` : `1px solid ${COLORS.SECONDARY_700}`,
      },
    }),
    placeholder: (provided, state) => ({
      ...provided,
      left: 0,
      right: 0,
      margin: 0,
      width: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      pointerEvents: 'auto',
      textOverflow: 'ellipsis',
      fontFamily: TYPOGRAPHY.SANS_SERIF,
      opacity: state.isDisabled ? 0.6 : 1,
      padding: '2px 8px',
      color: error ? COLORS.DANGER_500 : state.isDisabled ? COLORS.SECONDARY_500 : COLORS.SECONDARY_900,
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '2px 8px',
    }),
    multiValue: (provided) => ({
      ...provided,
      borderRadius: RADIUSES.sm,
      color: COLORS.SECONDARY_900,
      backgroundColor: COLORS.SECONDARY_300,
      '&:hover': {
        color: COLORS.PRIMARY_500,
        backgroundColor: COLORS.PRIMARY_200,
      },
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      borderRadius: 0,
      color: 'inherit',
      padding: '0.25rem',
      paddingLeft: '0.5rem',
      fontSize: TYPOGRAPHY.SMALL,
      fontFamily: TYPOGRAPHY.SANS_SERIF,
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      background: 'transparent',
      color: 'inherit',
      '&:hover': {
        color: 'inherit',
        background: 'transparent',
      },
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      minHeight: '2.25rem',
      alignSelf: 'start',
      padding: '0 0.5rem',
      color: error ? COLORS.DANGER_500 : state.isDisabled ? COLORS.SECONDARY_300 : COLORS.SECONDARY_700,
    }),
    container: (provided) => ({
      ...provided,
      border: 'none',
      flex: 1,
      borderColor: 'transparent',
    }),
    menu: (provided) => ({
      ...provided,
      border: 'none',
      padding: '0.5rem 0',
      zIndex: 2,
      borderRadius: '0.375rem',
      boxShadow: SHADOWS.DEPTH_5.SECONDARY_900,
    }),
    menuList: (provided) => ({
      ...provided,
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
      fontFamily: TYPOGRAPHY.SANS_SERIF,
      color: state.isFocused ? COLORS.PRIMARY_500 : COLORS.SECONDARY_900,
      backgroundColor: state.isFocused ? COLORS.PRIMARY_100 : COLORS.NEUTRAL_100,
      '&:active': {
        backgroundColor: COLORS.PRIMARY_200,
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      opacity: state.isDisabled ? 0.5 : 1,
      color: error ? COLORS.DANGER_500 : COLORS.SECONDARY_900,
      fontFamily: TYPOGRAPHY.SANS_SERIF,
    }),
  };
};

export const getCreatableStyles = (error: boolean): StylesConfig<unknown, boolean> => {
  return {
    control: (provided, state) => ({
      ...provided,
      border: error ? `1px solid ${COLORS.DANGER_500}` : `1px solid ${COLORS.SECONDARY_300}`,
      boxShadow: 'none',
      cursor: state.isDisabled ? 'not-allowed' : 'text',
      borderTopRightRadius: '0.375rem',
      borderBottomRightRadius: '0.375rem',
      borderTopLeftRadius: '0.375rem',
      borderBottomLeftRadius: '0.375rem',
      backgroundColor: 'rgba(15, 13, 10, 0.04)',
      '&:hover': {
        border: error ? `1px solid ${COLORS.DANGER_500}` : `1px solid ${COLORS.SECONDARY_300}`,
      },
    }),
    placeholder: (provided, state) => ({
      ...provided,
      left: 0,
      right: 0,
      margin: 0,
      width: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      pointerEvents: 'auto',
      textOverflow: 'ellipsis',
      fontFamily: TYPOGRAPHY.SANS_SERIF,
      opacity: state.isDisabled ? 0.6 : state.isFocused ? 0 : 1,
      padding: '2px 8px',
      color: error ? COLORS.DANGER_500 : state.isDisabled ? COLORS.SECONDARY_300 : COLORS.SECONDARY_500,
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '2px 8px',
    }),
    multiValue: (provided) => ({
      ...provided,
      borderRadius: RADIUSES.sm,
      color: COLORS.SECONDARY_900,
      cursor: 'pointer',
      backgroundColor: COLORS.SECONDARY_300,
      '&:hover': {
        color: COLORS.PRIMARY_500,
        backgroundColor: COLORS.PRIMARY_200,
      },
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      borderRadius: 0,
      color: 'inherit',
      padding: '0.25rem',
      paddingLeft: '0.5rem',
      fontSize: TYPOGRAPHY.SMALL,
      fontFamily: TYPOGRAPHY.SANS_SERIF,
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      background: 'transparent',
      color: 'inherit',
      '&:hover': {
        color: 'inherit',
        background: 'transparent',
      },
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      minHeight: '2.25rem',
      alignSelf: 'start',
      padding: '0 0.5rem',
      color: error ? COLORS.DANGER_500 : state.isDisabled ? COLORS.SECONDARY_300 : COLORS.SECONDARY_700,
    }),
    container: (provided) => ({
      ...provided,
      border: 'none',
      flex: 1,
      borderColor: 'transparent',
    }),
    menu: (provided) => ({
      ...provided,
      border: 'none',
      padding: '0.5rem 0',
      zIndex: 2,
      borderRadius: '0.375rem',
      boxShadow: SHADOWS.DEPTH_5.SECONDARY_900,
    }),
    menuList: (provided) => ({
      ...provided,
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
      fontFamily: TYPOGRAPHY.SANS_SERIF,
      color: state.isFocused ? COLORS.PRIMARY_500 : COLORS.SECONDARY_900,
      backgroundColor: state.isFocused ? COLORS.PRIMARY_100 : COLORS.NEUTRAL_100,
      '&:active': {
        backgroundColor: COLORS.PRIMARY_200,
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      opacity: state.isDisabled ? 0.5 : 1,
      color: error ? COLORS.DANGER_500 : COLORS.SECONDARY_900,
      fontFamily: TYPOGRAPHY.SANS_SERIF,
    }),
  };
};

export const IconControl = ({ children, ...props }: ControlProps<unknown, boolean> & { icon: ReactNode }) => {
  const { icon } = props;

  return (
    <components.Control {...props}>
      {icon}
      {children}
    </components.Control>
  );
};

export const IconOption = (props: OptionProps<Option, boolean> & { optionIcon: ReactNode }) => {
  const { optionIcon, data } = props;

  return (
    <components.Option {...props} className="layout-row layout-align-start-center">
      {optionIcon}
      <span>{data.label}</span>
    </components.Option>
  );
};

export const ABOVE_INTERCOM_INDEX = 2147483010;
